import Phaser from "phaser"
import { Vector2 } from "three"

export class Landing extends Phaser.Scene {
  constructor() {
    super("landing")
  }

  init() {}

  preload() {
    // load font
    this.load.script("webfont", "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js")
  }

  create() {
    // play audio bgmusic
    this.sound.play("bgmusic", {
      loop: true,
    })
    this.redbg = this.add.image(this.game.config.width * 0.5, this.game.config.height * 0.5, "redbg").setOrigin(0.5)
    this.redbg.setDisplaySize(this.game.config.width * 1.1, this.game.config.height)
    this.redbg.setDepth(-100)

    this.landingVideo = this.add.sprite(this.game.config.width * 0.5, this.game.config.height * 0.45, "Front_Anim_00000")
    this.landingVideo.setDisplaySize(this.game.config.height * 0.53, this.game.config.height * 0.53)

    this.time.addEvent({
      delay: 500,
      callback: () => {
        this.landingVideo.anims.play("landingvideo").on("animationcomplete", () => {
          this.landingVideo.anims.play("closeEye")
        })
      },
    })

    this.cup = this.add.image(this.game.config.width * 0.5, this.landingVideo.y + ((this.game.config.height * 0.53 * 1) / 10) * 0.5, "cup").setOrigin(0.5)
    this.cup.setDisplaySize(this.game.config.height * 0.53, (this.game.config.height * 0.53 * 13) / 10)

    // yellowball
    this.yellowball = this.add.image(this.game.config.width * 0.5, this.game.config.height * 1 + this.game.config.width * 0.6, "yellowball").setOrigin(0.5)
    this.yellowball.setDisplaySize(this.game.config.height * 0.65, this.game.config.height * 0.65).setDepth(10)

    this.light = this.add
      .image(this.game.config.width - this.game.config.height * 0.165 * 1.77 * 0.3, this.game.config.height - this.game.config.height * 0.165 * 0.6, "light")
      .setOrigin(0.5)
    this.light.setDisplaySize(this.game.config.height * 0.11 * 1.65, this.game.config.height * 0.11)

    this.logoLanding = this.add.image(this.game.config.width * 0.5, this.game.config.height * 0.1, "logolanding").setOrigin(0.5)
    this.logoLanding.setDisplaySize(this.game.config.height * 0.15 * 1.65, this.game.config.height * 0.15)
  }
  startGame() {
    let three = document.getElementById("canvas-container")
    three.style.opacity = 1

    this.tweens.add({
      targets: [this.redbg, this.logoLanding, this.light],
      duration: 700,
      ease: "linear",
      alpha: 0,
      onComplete: () => {
        this.scene.start("game")
      },
    })
  }

  startYellowBall() {
    this.tweens.add({
      targets: [this.landingVideo],
      alpha: 0,
      duration: 500,
      ease: "linear",
      delay: 300,
      onComplete: () => {
        this.cup.destroy()
      },
    })
    this.tweens.add({
      targets: [this.yellowball],
      y: -1 * this.game.config.width * 0.6,
      duration: 1500,
      rotation: Math.PI,
      ease: "linear",
      onComplete: () => {
        this.yellowball.destroy()
      },
    })
  }

  update() {}
}
