import * as THREE from "three"
import React, { useEffect, useMemo, useRef } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"
import { BallCollider, RigidBody, CylinderCollider, interactionGroups } from "@react-three/rapier"
import { useStore } from "../../store/index"
import { generateTexture } from "./utils/index.js"
import { meterialControl } from "../../helper/meterial"

const capMaterial = new THREE.MeshStandardMaterial({ metalness: 0.75, roughness: 0.15, color: "#00492f", emissive: "#600000", envMapIntensity: 1 })

function BallBall({ phaserRef, vec = new THREE.Vector3(), scale, id, position, targetPos, r = THREE.MathUtils.randFloatSpread }) {
  const api = useRef()

  const { nodes } = useGLTF("/model/Biscuit_Bag_v2_low.glb")
  const { nodes: capnode } = useGLTF("/cap.glb")

  const [disable, setDisable] = React.useState(false)
  const [triggerUp, setTriggerUp] = React.useState(false)
  const { viewport } = useThree()
  const texture = new THREE.CanvasTexture(generateTexture("white"))

  const zoneControls = 0.8

  const [random, setRandom] = React.useState({
    x: Math.random() * viewport.width * zoneControls - (viewport.width * zoneControls) / 2,
    y: Math.random() * viewport.height * zoneControls - (viewport.height * zoneControls) / 2,
  })
  const actions = useStore((state) => state.actions)
  const currentType = useStore((state) => state.currentType)
  const superPowerColor = useStore((state) => state.superPowerColor)

  const { nodes: nodeGreen } = useGLTF("/model/Ball_deer_v2.glb")
  const { nodes: nodeGold } = useGLTF("/model/Ball_bird_logo.glb")
  const { nodes: nodeRed } = useGLTF("/model/Ball_nutcracker.glb")
  const { nodes: nodeBlue } = useGLTF("/model/Ball_snowman.glb")

  const Mainnodes = useMemo(() => {
    switch (superPowerColor) {
      case 1:
        return nodeRed
      case 2:
        return nodeGold
      case 3:
        return nodeGreen
      case 4:
        return nodeBlue
      default:
        return {
          Sphere: {
            geometry: nodeBlue.Sphere.geometry,
            material: new THREE.MeshPhysicalMaterial({
              thickness: 0.1,
              opacity: 1,
              roughness: 0.1,
              transparent: true,
              transmission: 1,
              alphaMap: texture,
              envMapIntensity: 2,
            }),
          },
          sticker: {
            geometry: new THREE.SphereGeometry(0.9, 28, 28),
            material: new THREE.MeshPhysicalMaterial({
              opacity: 0,
              transmission: 1,
              transparent: true,
            }),
          },
        }
    }
  }, [superPowerColor])

  useFrame((state, delta) => {
    if (!disable) {
      let totarget = targetPos ?? new THREE.Vector3(random.x, random.y, 0)
      let current = new THREE.Vector3(api.current.translation().x, api.current.translation().y, api.current.translation().z)
      // initial status - top
      if (current.y > viewport.height) {
        actions.add()
        actions.remove(id)
      }

      // to target position logic
      if (current.distanceTo(totarget) < 0.05) {
        setTriggerUp(true)
      } else if (!triggerUp) {
        // move to target
        let direction = totarget.sub(current).normalize()
        api.current.applyImpulse(new THREE.Vector3(direction.x * 100 * delta, direction.y * 100 * delta, direction.z * 100 * delta))
      } else {
        // logic to move up
        api.current.applyImpulse(new THREE.Vector3(0, 60 * delta, 0))
      }
    } else {
      if (api.current.translation().y < viewport.height * -1) {
        // initial status -bottom
        setDisable(false)
      }
    }
  })

  return (
    <RigidBody linearDamping={0.75} angularDamping={0.15} friction={0.2} position={position} ref={api} colliders={false} dispose={null}>
      <BallCollider
        args={[scale]}
        collisionGroups={disable ? interactionGroups(Math.floor(Math.random) * -1000, [Math.floor(Math.random) * -1000]) : interactionGroups(1, [1])}
      />
      <CylinderCollider
        collisionGroups={disable ? interactionGroups(Math.floor(Math.random) * -1000, [Math.floor(Math.random) * -1000]) : interactionGroups(1, [1])}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 0, 1.2 * scale]}
        args={[0.15 * scale, 0.275 * scale]}
      />

      {/* ball */}
      <mesh castShadow scale={0.85 * scale} position={[0, 0, 0.35 * scale]} rotation={[-5, 0, 0]} geometry={nodeBlue.Sphere.geometry}>
        <meshPhysicalMaterial {...Mainnodes.Sphere.material} />
      </mesh>

      {/* sticker */}
      <mesh
        castShadow
        scale={0.851 * scale}
        position={[0, 0, 0.35 * scale]}
        rotation={[-5, 0, 0]}
        geometry={Mainnodes.sticker.geometry}
        material={Mainnodes.sticker.material}
      />

      {/* bag */}
      <mesh
        castShadow
        scale={0.3 * scale}
        position={[0, 0, 0.4 * scale]}
        rotation={[3, 0, 0]}
        geometry={nodes.BezierCurve002.geometry}
        material={nodes.BezierCurve002.material}
      />
      {/* cap */}
      <mesh position={[0, 0, -0.85 * scale]} castShadow scale={1.8 * scale} geometry={capnode.Mesh_1.geometry} material={capMaterial} />

      <mesh
        position={[0, 0, 0.2 * scale]}
        scale={0.851 * scale}
        onPointerEnter={(e) => {
          if (superPowerColor) {
            e.stopPropagation()
            setDisable(true)
            actions.couldAdd()
            actions.remove(id)
            phaserRef.current.scene.scenes[1].setEmitterPosition(e)
            phaserRef.current.scene.scenes[1].scaleAnimation()
          } else {
            e.stopPropagation()
            actions.setColor()
            actions.couldAdd()
            actions.remove(id)
            phaserRef.current.scene.scenes[1].setEmitterPosition(e)
          }
        }}
        onClick={(e) => {
          if (superPowerColor) {
            e.stopPropagation()
            setDisable(true)
            actions.couldAdd()
            actions.remove(id)
            phaserRef.current.scene.scenes[1].setEmitterPosition(e)
            phaserRef.current.scene.scenes[1].scaleAnimation()
          } else {
            e.stopPropagation()
            actions.setColor()
            actions.couldAdd()
            actions.remove(id)
            phaserRef.current.scene.scenes[1].setEmitterPosition(e)
          }
        }}>
        <boxGeometry args={[2, 2, 2]} />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>
    </RigidBody>
  )
}

export default BallBall
