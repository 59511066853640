import BallBall from "./model/bagBall"
import Ball from "./model/ball"
import { useStore } from "../store/index"

function Bubbles(props) {
  const phaserRef = props.phaserRef
  const bubblesStore = useStore((state) => state.bubblesStore)

  return (
    <>
      {bubblesStore.map((item, index) => {
        if (item) {
          switch (item.type) {
            case 5:
              return <BallBall phaserRef={phaserRef} position={item.position} scale={1} key={index} id={index} type={item.type} />
            default:
              return <Ball phaserRef={phaserRef} position={item.position} scale={1} key={index} id={index} type={item.type} />
          }
        } else {
          return null
        }
      })}
    </>
  )
}

export default Bubbles
