import * as THREE from "three"
import React, { Suspense, useEffect, useMemo } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { Environment, useGLTF } from "@react-three/drei"
import { EffectComposer, N8AO } from "@react-three/postprocessing"
import { Physics } from "@react-three/rapier"
import Bubbles from "../component3d/ballhub"
import { useStore } from "../store/index"

THREE.ColorManagement.legacyMode = false

export const THREECANVAS = ({ phaserRef }) => {
  const actions = useStore((state) => state.actions)
  const timerHander = useStore((state) => state.timerHander)

  useEffect(() => {
    window.start = () => {
      timerHander.init()
      actions.setStartGame()
    }

    window.setColor = (e) => {
      actions.setColor(e)
    }
  }, [])

  return (
    <Suspense fallback={null}>
      <div
        id="canvas-container"
        style={{
          backgroundImage: "url(/2d/BG.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100svh",
          opacity: 0,
        }}>
        <Canvas
          shadows
          gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
          camera={{ position: [0, 0, 20], fov: 32.5, near: 0.1, far: 100 }}
          id="THREECANVAS"
          onCreated={(state) => {
            state.gl.toneMappingExposure = 1.5
            // state.gl.setClearColor("transparent")
            actions.init(state)
          }}>
          <PhysisStore phaserRef={phaserRef} />
          <Control phaserRef={phaserRef} />
          <EffectComposer disableNormalPass multisampling={0}>
            <N8AO halfRes color="red" aoRadius={2} intensity={0.8} aoSamples={6} denoiseSamples={4} />
          </EffectComposer>
          <Environment files="/royal_esplanade_1k.hdr" />
        </Canvas>
      </div>
    </Suspense>
  )
}

const Control = ({ phaserRef }) => {
  const { endGame, timmer, currentType, startGame, timerHander, superPowerColor } = useStore((state) => state)

  useMemo(() => {
    if (timmer >= -0.1) {
      if (phaserRef.current && !superPowerColor) {
        if (startGame && currentType) {
          phaserRef.current.scene.scenes[1].switchComponent(currentType, false)
        }
      } else {
        phaserRef.current.scene.scenes[1].switchComponent(superPowerColor, true)
      }
    }
  }, [currentType, startGame, superPowerColor])

  useMemo(() => {
    if (phaserRef.current && timmer >= -0.1) {
      if (superPowerColor) {
        phaserRef.current.scene.scenes[1].triggerSuperPower()
      } else {
        phaserRef.current.scene.scenes[1].destroySuperPower()
      }
    }
  }, [superPowerColor])

  useFrame(() => {
    if (phaserRef.current && startGame && timmer >= -0.1) {
      phaserRef.current.scene.scenes[1].circularProgress.value = timmer
    }
  })
  return null
}

const PhysisStore = ({ phaserRef }) => {
  return (
    <Physics gravity={[0, -10, 0]}>
      <Bubbles scale={1} phaserRef={phaserRef} />
    </Physics>
  )
}
