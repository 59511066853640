import { create } from "zustand"
import * as THREE from "three"
import { addEffect } from "@react-three/fiber"

function claim(value, min, max) {
  return Math.min(Math.max(value, min), max)
}

const r = THREE.MathUtils.randFloatSpread
const totalType = 4

export const useStore = create((set, get) => {
  return {
    bubblesStore: [
      {
        position: [r(15), r(10) - 15, r(10) - 5],
        createTime: Date.now(),
        type: 1,
        id: 1,
      },
      {
        position: [r(15), r(10) - 15, r(10) - 5],
        createTime: Date.now(),
        type: 2,
        id: 2,
      },
      {
        position: [r(15), r(10) - 15, r(10) - 5],
        createTime: Date.now(),
        type: 3,
        id: 3,
      },
      {
        position: [r(15), r(10) - 15, r(10) - 5],
        createTime: Date.now(),
        type: 4,
        id: 4,
      },
      {
        position: [r(15), r(10) - 15, r(10) - 5],
        createTime: Date.now(),
        type: 5,
        id: 5,
      },
    ],
    startGame: false,

    timmer: 1.0,

    limit: 25,

    currentType: 1,

    superPowerColor: false,

    endGame: false,

    shareImgUrl: "/2d/final.jpg",

    setShareImgUrl: (e) => {
      set((state) => ({
        shareImgUrl: e,
      }))
    },

    timerHander: {
      init() {
        let startTime = Date.now()
        let _calTime = 6000
        let _calTime_currentBall = 5000
        let _indexSpeed = 1
        let _superPowerTime = 5000
        addEffect(() => {
          const currentTime = Date.now()
          // hander deduct time
          if (!get().endGame && currentTime - startTime > 100 && get().startGame) {
            startTime = currentTime
            set((state) => ({
              timmer: state.timmer - 0.01 * _indexSpeed,
            }))

            // hander end game
            if (get().timmer <= -0.1) {
              set((state) => ({
                endGame: true,
              }))
            }

            // hander deduction speed
            if (_calTime <= 0) {
              _calTime = 6000
              _indexSpeed *= 1.05
            } else {
              _calTime -= 100
            }

            // hander random ball timmer
            if (get().superPowerColor && _superPowerTime >= 0) {
              _calTime_currentBall = 7000
              _superPowerTime -= 100

              if (_superPowerTime <= 0) {
                set((state) => ({
                  superPowerColor: false,
                }))
                _superPowerTime = 5000
              }
            } else if (_calTime_currentBall <= 0) {
              let arr = [1, 2, 3, 4]

              let _randomType = arr[Math.floor(Math.random() * arr.length)]
              set((state) => ({
                currentType: _randomType,
              }))
              _calTime_currentBall = 7000
            } else {
              _calTime_currentBall -= 100
            }
          }
        })
      },

      getTimmer() {
        return get().timmer
      },

      addTime(e) {
        get().timmer < 1 &&
          set((state) => ({
            timmer: state.timmer + 0.1,
          }))
      },
    },

    actions: {
      init(state) {
        const { viewport } = state

        let startTime = Date.now()
        addEffect(() => {
          const currentTime = Date.now()
          if (currentTime - startTime > 700) {
            startTime = currentTime

            // add random type
            let randomType = Math.ceil(Math.random() * totalType)
            if (Math.random() < 0.11) {
              randomType = 5
            }
            if (get().bubblesStore.filter((item) => item).length < get().limit) {
              set((state) => ({
                bubblesStore: [
                  ...state.bubblesStore,
                  {
                    position: [r(15), r(10) - 15, r(10) - 5],
                    createTime: Date.now(),
                    type: randomType,
                    id: state.bubblesStore.length,
                  },
                ],
              }))
            }
          }
        })
      },

      setColor() {
        let arr = [1, 2, 3, 4]
        let color = arr[Math.floor(Math.random() * arr.length)]

        set((state) => ({
          superPowerColor: color,
        }))
      },

      setStartGame() {
        set((state) => ({
          startGame: true,
        }))
      },

      setStopGame() {
        set((state) => ({
          startGame: false,
        }))
      },

      add() {
        let randomType = Math.ceil(Math.random() * totalType)
        if (get().bubblesStore.filter((item) => item).length < get().limit) {
          set((state) => ({
            bubblesStore: [
              ...state.bubblesStore,
              {
                position: [r(15), r(10) - 15, r(10) - 5],
                createTime: Date.now(),
                type: randomType,
                id: state.bubblesStore.length,
              },
            ],
          }))
        }
      },

      couldAdd() {
        get().timerHander.addTime()

        let _percentage = Math.random()
        if (_percentage > 0.4 && get().bubblesStore.filter((item) => item).length < get().limit) {
          set((state) => ({
            bubblesStore: [
              ...state.bubblesStore,
              {
                position: [r(20), r(20) - 25, r(20) - 15],
                createTime: Date.now(),
                type: Math.ceil(Math.random() * totalType),
                id: state.bubblesStore.length,
              },
            ],
          }))
        }
      },

      getTotallive() {
        return get().bubblesStore.filter((item) => item)
      },

      remove(index) {
        // hander add time

        set((state) => {
          const newArray = [...state.bubblesStore] // Create a copy of the array
          newArray[index] = false // Change the value at the specified index
          return {
            bubblesStore: [...newArray],
          }
        })
      },
    },
  }
})
