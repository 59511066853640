import * as THREE from "three"
import React, { useEffect, useMemo, useRef } from "react"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"
import { BallCollider, RigidBody, CylinderCollider, interactionGroups } from "@react-three/rapier"
import gsap from "gsap"
import { useStore } from "../../store/index"
import { meterialControl } from "../../helper/meterial"

const capMaterial = new THREE.MeshStandardMaterial({ metalness: 0.75, roughness: 0.15, color: "#00492f", emissive: "#600000", envMapIntensity: 1 })

function RedBall({ triggerBall, type, phaserRef, vec = new THREE.Vector3(), scale, id = 1, position, targetPos, r = THREE.MathUtils.randFloatSpread }) {
  const api = useRef()
  const capRef = useRef(false)
  const snowRef = useRef()

  const actions = useStore((state) => state.actions)
  const currentType = useStore((state) => state.currentType)
  const superPowerColor = useStore((state) => state.superPowerColor)

  const { nodes: nodeGreen } = useGLTF("/model/Ball_deer_v2.glb")
  const { nodes: nodeGold } = useGLTF("/model/Ball_bird_logo.glb")
  const { nodes: nodeRed } = useGLTF("/model/Ball_nutcracker.glb")
  const { nodes: nodeBlue } = useGLTF("/model/Ball_snowman.glb")

  const nodes = useMemo(() => {
    switch (superPowerColor) {
      case 1:
        return nodeRed
      case 2:
        return nodeGold
      case 3:
        return nodeGreen
      case 4:
        return nodeBlue
      default:
        switch (type) {
          case 1:
            return nodeRed
          case 2:
            return nodeGold
          case 3:
            return nodeGreen
          case 4:
            return nodeBlue
        }
    }
  }, [superPowerColor])

  const { nodes: capnode } = useGLTF("/cap.glb")
  const [disable, setDisable] = React.useState(false)
  const [triggerUp, setTriggerUp] = React.useState(false)
  const { viewport } = useThree()

  const zoneControls = 0.8
  const oriPositionCap = [0, 0, -0.85 * scale]
  const oriPostionSnowMan = [0, 0, 0.35 * scale]

  const [random, setRandom] = React.useState({
    x: Math.random() * viewport.width * zoneControls - (viewport.width * zoneControls) / 2,
    y: Math.random() * viewport.height * zoneControls - (viewport.height * zoneControls) / 2,
  })

  useFrame((state, delta) => {
    let totarget = targetPos ?? new THREE.Vector3(random.x, random.y, 0)
    let current = new THREE.Vector3(api.current.translation().x, api.current.translation().y, api.current.translation().z)
    // initial status - top
    if (current.y > viewport.height) {
      actions.add()
      actions.remove(id)
    }

    // to target position logic
    if (current.distanceTo(totarget) < 0.05) {
      setTriggerUp(true)
    } else if (!triggerUp) {
      // move to target
      let direction = totarget.sub(current).normalize()
      api.current.applyImpulse(new THREE.Vector3(direction.x * 100 * delta, direction.y * 100 * delta, direction.z * 100 * delta))
    } else {
      // logic to move up
      api.current.applyImpulse(new THREE.Vector3(0, 60 * delta, 0))
    }
  })

  const trigger = (e) => {
    if (superPowerColor) {
      e.stopPropagation()
      setDisable(true)
      actions.couldAdd()
      actions.remove(id)
      phaserRef.current.scene.scenes[1].setEmitterPosition(e)
      phaserRef.current.scene.scenes[1].scaleAnimation()
    } else if (type === currentType) {
      e.stopPropagation()
      setDisable(true)
      actions.couldAdd()
      actions.remove(id)
      phaserRef.current.scene.scenes[1].setEmitterPosition(e)
      phaserRef.current.scene.scenes[1].scaleAnimation()
    } else {
      // api.current.applyImpulse(new THREE.Vector3(0, 0, 70))
    }
  }

  return (
    <RigidBody linearDamping={0.75} angularDamping={0.15} friction={0.2} position={position} ref={api} colliders={false} dispose={null}>
      <BallCollider
        args={[scale]}
        collisionGroups={disable ? interactionGroups(Math.floor(Math.random) * -1000, [Math.floor(Math.random) * -1000]) : interactionGroups(1, [1])}
      />
      <CylinderCollider
        collisionGroups={disable ? interactionGroups(Math.floor(Math.random) * -1000, [Math.floor(Math.random) * -1000]) : interactionGroups(1, [1])}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 0, 1.2 * scale]}
        args={[0.15 * scale, 0.275 * scale]}
      />

      <mesh castShadow scale={0.85 * scale} position={oriPostionSnowMan} rotation={[-5, 0, 0]} ref={snowRef} geometry={nodes.Sphere.geometry}>
        <meshPhysicalMaterial {...nodes.Sphere.material} {...meterialControl} />
      </mesh>
      <mesh
        castShadow
        scale={0.851 * scale}
        position={oriPostionSnowMan}
        rotation={[-5, 0, 0]}
        ref={snowRef}
        geometry={nodes.sticker.geometry}
        material={nodes.sticker.material}
      />
      <mesh position={oriPositionCap} ref={capRef} castShadow scale={1.8 * scale} geometry={capnode.Mesh_1.geometry} material={capMaterial} />

      <mesh geometry={nodes.Sphere.geometry} position={oriPostionSnowMan} scale={1.1} onPointerEnter={(e) => trigger(e)} onClick={(e) => trigger(e)}>
        <meshBasicMaterial transparent opacity={0} alphaTest={0} />
      </mesh>
    </RigidBody>
  )
}

export default RedBall
